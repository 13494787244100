<template>
    <div class="getAllFeedDashboard">
        <div class="getAllFeedDashboard__header">
            <div class="getAllFeedDashboard__title">Get all feed</div>
        </div>
        <div class="getAllFeedDashboard__button">
          <span class="text" @click="exportToExcel">Export</span>
        </div>
    </div>
</template>

<script>
import './GetAllFeed.styl'
import { blogsAPI } from '@/api/blog';

export default {
    name: 'get-all-feed',
    methods: {
      async exportToExcel () {
        try {
          const { data } = await blogsAPI.getAllFeed()
          let allFeed = 'Category,Search word,Title,Link\r\n';
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].posts.length; j++) {
                allFeed += `${data[i].title},${data[i].posts[j].searchWord},${data[i].posts[j].title},${data[i].posts[j].link}\r\n`;
            }
          }
          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', allFeed]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'all_feed_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e)
        }
      },
    },
}
</script>